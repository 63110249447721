// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import classnames from 'classnames'

import map from 'lodash/map'
import filter from 'lodash/filter'
import isUndefined from 'lodash/isUndefined'
import groupBy from 'lodash/groupBy'
import head from 'lodash/head'
import takeRight from 'lodash/takeRight'
import matches from 'lodash/matches'
import replace from 'lodash/replace'
import isInteger from 'lodash/isInteger'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import strContains from '../../methods/str-contains'
import about from '../../seo/about.json'

import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    allResources(sort: { order: ASC, fields: position }) {
      edges {
        node {
          title {
            lang
            content
          }
          position
          routeSlug
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** makeTree */
const makeTree = (children, uri, originalPath, expanded, expand) => (
  <Fragment>
    {map(children, (child) => (
      <li
        className={classnames({
          expanded: child.routeSlug.includes(expanded),
        })}
      >
        {isUndefined(child.children) === true && (
          <Link
            to={child.routeSlug}
            className={
              strContains(uri, child.routeSlug) === true ? 'active' : 'passive'
            }
          >
            {child.intlTitle}
          </Link>
        )}
        {isUndefined(child.children) === false && (
          <Fragment>
            {child.children.length !== 0 && (
              <Link
                to={child.routeSlug}
                // onClick={(e) => {
                //   e.preventDefault()
                //   expand(child.routeSlug)
                // }}
                className={
                  strContains(uri, child.routeSlug) === true
                    ? 'active'
                    : 'passive'
                }
              >
                {child.intlTitle}
              </Link>
            )}
            {child.children.length === 0 && (
              <Link
                to={child.routeSlug}
                className={
                  strContains(uri, child.routeSlug) === true
                    ? 'active'
                    : 'passive'
                }
              >
                {child.intlTitle}
              </Link>
            )}
          </Fragment>
        )}
        <Fragment>
          {isUndefined(child.children) === false && (
            <Fragment>
              {child.children.length !== 0 && (
                <ul>
                  {makeTree(
                    child.children,
                    uri,
                    originalPath,
                    expanded,
                    expand
                  )}
                </ul>
              )}
            </Fragment>
          )}
        </Fragment>
      </li>
    ))}
  </Fragment>
)

/** TableOfContents */
class TableOfContents extends React.PureComponent {
  /** [constructor description] */
  constructor(props) {
    super(props)

    const { expanded: givenExpanded } = this.props

    this.state = {
      isActive: false,
      whatsActive: 'root',
      expanded: givenExpanded,
    }

    this.update = this.update.bind(this)
    this.expand = this.expand.bind(this)
    this.linkRef = React.createRef()
  }

  /** [update description] */
  update(isActive, whatsActive) {
    this.setState({ isActive, whatsActive })
  }

  /** [update description] */
  expand(expanded) {
    this.setState({ expanded })
  }

  /** [render description] */
  render() {
    const { uri, lang, treeData, originalPath } = this.props
    const { isActive, whatsActive, expanded } = this.state

    return (
      <Fragment>
        <nav>
          <li>
            <Link to="/">{treeData.intlTitle}</Link>
          </li>
          {makeTree(
            treeData.children,
            uri,
            originalPath,
            expanded,
            this.expand
          )}
        </nav>
        <ul className="etc">
          <li>
            <Link to="/audio-archive">Audio Archive</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/buy-or-contribute">Buy or Contribute</Link>
          </li>
          <li>
            <Link to="/impressum">Impressum</Link>
          </li>
        </ul>
      </Fragment>
    )
  }
}

/** Header */
const Header = ({ uri, originalPath, lang }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const {
        allResources: { edges },
      } = data

      const nodes = map(edges, 'node').slice(0, -1)
      const intlNodes = map(nodes, (node) => ({
        ...node,
        intlTitle: filter(node.title, ['lang', lang])[0].content,
      }))
      const nodePositions = groupBy(map(intlNodes, 'position'), Math.floor)
      const topLevelNodes = filter(nodes, ({ position }) => isInteger(position))
      const match = filter(topLevelNodes, ({ routeSlug }) =>
        strContains(uri, routeSlug)
      )
      let expanded = originalPath

      if (isUndefined(match[0]) === false) {
        expanded = match[0].routeSlug
      }

      const treeData = {
        intlTitle: about.altTitle,
        key: '0-0-0',
        children: [],
      }

      map(nodePositions, (nodePosition, index) => {
        const hasChildren = nodePosition.length > 1
        const first = head(nodePosition, 1)
        const children =
          hasChildren === true
            ? takeRight(nodePosition, nodePosition.length - 1)
            : []
        const parent = filter(intlNodes, matches({ position: first }))[0]
        const treeNode = {
          // intlTitle: `${index}. ${parent.intlTitle}`,
          intlTitle: `${parent.intlTitle}`,
          key: `0-${replace(parent.position, '.', '-')}-0`,
          children: [],
          routeSlug: parent.routeSlug,
        }
        // const parentIndex = index

        map(children, (child, index) => {
          const node = filter(intlNodes, matches({ position: child }))[0]

          treeNode.children.push({
            // intlTitle: `${parentIndex}.${index + 1}. ${node.intlTitle}`,
            intlTitle: `${node.intlTitle}`,
            key: `0-${replace(node.position, '.', '-')}`,
            routeSlug: `${node.routeSlug}`,
          })
        })

        treeData.children.push(treeNode)
      })

      return (
        <div className="fixed">
          <TableOfContents
            uri={uri}
            lang={lang}
            treeData={treeData}
            originalPath={originalPath}
            expanded={expanded}
          />
        </div>
      )
    }}
  />
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Header
