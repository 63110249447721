// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React, { useEffect } from 'react'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

import classnames from 'classnames'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { globalHistory } from '@reach/router'
import Iframe from 'react-iframe'
import { Spring, animated } from 'react-spring'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import { updateAudioPlayerState } from '../../state/actions'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** AudioPlayer */
const AudioPlayer = ({ audioPlayerState, updateAudioPlayerState }) => {
  useEffect(
    () =>
      globalHistory.listen(({ action }) => {
        if (action === 'PUSH') {
          updateAudioPlayerState({
            active: false,
            buzzsproutTag: 'NA',
          })
        }
      }),
    [updateAudioPlayerState]
  )

  const { active, buzzsproutTag } = audioPlayerState
  const tag = buzzsproutTag.split(' ').join('+')
  const buzzsproutUrl = `https://www.buzzsprout.com/1897213?artist=&client_source=large_player&iframe=true&referrer=https%3A%2F%2Fwww.buzzsprout.com%2F1897213%2Fpodcast%2Fembed&tags=${tag}`
  console.log(tag)

  return (
    <Spring
      native
      reset
      reverse={!active}
      from={{ height: 0 }}
      to={{
        height: active === true ? 420 : 0,
      }}
    >
      {(props) => (
        <animated.aside
          className={classnames(
            'audio-player',
            'container',
            active === true ? 'active' : 'passive'
          )}
          style={{
            height: props.height,
            maxHeight: props.height,
            padding: active === true ? '0.55rem' : '0rem',
            border: active === true ? '2px solid var(--border-color)' : 'unset',
          }}
        >
          {active === true && (
            <Fragment>
              {buzzsproutTag !== 'NA' && (
                <Iframe
                  url={buzzsproutUrl}
                  width="100%"
                  height="375px"
                  display="initial"
                  position="relative"
                />
              )}
            </Fragment>
          )}
          <Link
            style={{
              display: 'block',
              marginBottom: 0,
              marginTop: -10,
              textAlign: 'right',
            }}
            to="#"
            onClick={(e) => {
              e.preventDefault()
              updateAudioPlayerState({
                active: false,
                buzzsproutTag: 'NA',
              })
            }}
          >
            <small>Close</small>
          </Link>
        </animated.aside>
      )}
    </Spring>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    audioPlayerState: state.audioPlayerState,
  }),
  (dispatch) => ({
    updateAudioPlayerState(payload) {
      dispatch(updateAudioPlayerState(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedAudioPlayer = compose(
  withState // Add state
)(AudioPlayer)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedAudioPlayer
